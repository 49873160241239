import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "admin-panel flex" }
const _hoisted_2 = { class: "flex flex-column flex-auto admin-panel__content px3" }
const _hoisted_3 = { class: "flex items-center justify-center center admin-panel-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavigation = _resolveComponent("AdminPanelNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (!_ctx.accessLoading)
        ? (_openBlock(), _createBlock(_component_AdminPanelNavigation, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_2, [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
          : (_openBlock(), _createBlock(_component_ProgressSpinner, {
              key: 1,
              class: "p-progress-spinner--primary p-progress-spinner--panel",
              style: {"width":"50px","height":"50px"},
              "stroke-width": "5"
            }))
      ])
    ]),
    _createVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('adminPanel.onlyDesktop')), 1)
  ], 64))
}