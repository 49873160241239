<template>
  <div class="admin-panel-help">
    <AdminPanelHeader :title="$t('adminPanel.help.title')">
      <a target="_blank" class="contact" href="mailto:contact@getremotefun.com">
        {{ `contact@getremotefun.com` }}
      </a>
    </AdminPanelHeader>
    <div class="admin-panel-help__content flex">
      <div class="admin-panel-help__questions flex-auto">
        <div
          class="admin-panel-help__question flex justify-between"
          @click="openQuestion = 'howToStart'"
        >
          {{ $t('adminPanel.help.howToStart.question') }}
          <!--          <i-->
          <!--            class="pi mr2"-->
          <!--            :class="`pi-chevron-${openQuestion === 'howToStart' ? 'up' : 'down'}`"-->
          <!--          ></i>-->
        </div>
        <div class="flex">
          <div v-for="i in 3" :key="i" class="admin-panel-help__answer my2 mr2">
            <span class="admin-panel-help__answer-number mb1">{{ i }}</span>
            {{ $t(`adminPanel.help.howToStart.answers.${i - 1}`) }}
          </div>
        </div>
        <div
          class="admin-panel-help__question flex justify-between mt3"
          @click="openQuestion = 'howToCustomizeGames'"
        >
          {{ $t('adminPanel.help.howToCustomizeGames.question') }}
          <!--          <i-->
          <!--            class="pi mr2"-->
          <!--            :class="`pi-chevron-${openQuestion === 'howToCustomizeGames' ? 'up' : 'down'}`"-->
          <!--          ></i>-->
        </div>
        <div class="flex">
          <div v-for="i in 3" :key="i" class="admin-panel-help__answer my2 mr2">
            <span class="admin-panel-help__answer-number mb1">{{ i }}</span>
            {{ $t(`adminPanel.help.howToCustomizeGames.answers.${i - 1}`) }}
          </div>
        </div>
      </div>
      <div>
        <div class="flex flex-column admin-panel-help__files">
          <div class="title">{{ $t('adminPanel.help.files.title') }}</div>
          <a
            class="admin-panel-help__files-item mb2"
            :href="`https://getremotefun.com/RemoteFun_Brochure_${$i18n.locale}.pdf`"
            target="_blank"
          >
            <Button>
              <i class="pi pi-download"></i>
              {{ $t('adminPanel.help.files.brochure') }} (1.9MB)
            </Button>
          </a>
          <a
            class="admin-panel-help__files-item mb2"
            :href="`https://getremotefun.com/RemoteFun_FAQ_${$i18n.locale}.pdf`"
            target="_blank"
          >
            <Button>
              <i class="pi pi-download"></i>
              {{ $t('adminPanel.help.files.faq') }} (1.5MB)
            </Button>
          </a>
          <a
            class="admin-panel-help__files-item mb2"
            :href="`https://getremotefun.com/RemoteFun_Instructions_${$i18n.locale}.pdf`"
            target="_blank"
          >
            <Button>
              <i class="pi pi-download"></i>
              {{ $t('adminPanel.help.files.instructions') }} (1.3MB)
            </Button>
          </a>
        </div>
        <AdminPanelFeedback class="mt3" />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import AdminPanelFeedback from '@/components/admin-panel/AdminPanelFeedback.vue';

  export default {
    name: 'AdminPanelHelp',
    components: {
      AdminPanelHeader,
      AdminPanelFeedback
    },
    setup() {
      const { t } = useI18n();

      const instructions = computed(() => [
        t('adminPanel.shortInstructions.0'),
        t('adminPanel.shortInstructions.1'),
        t('adminPanel.shortInstructions.2'),
        t('adminPanel.shortInstructions.3'),
        t('adminPanel.shortInstructions.4')
      ]);

      const openQuestion = ref('howToStart');

      return { instructions, openQuestion };
    }
  };
</script>

<style scoped lang="scss">
  .admin-panel-help {
    .contact {
      line-height: 36px;
    }

    &__questions {
      padding-right: global-vars(ui-default-measure4x);
    }

    &__question {
      @include font(16px, 600);
      padding: global-vars(ui-default-measure);
      border-radius: global-vars(ui-default-measure);
      background: white;
    }

    &__answer {
      width: 33%;
      @include font(14px, 500, 20px);

      @include lg-up() {
        @include font(15px, 500, 20px);
      }

      &-number {
        width: global-vars(ui-default-measure4x);
        height: global-vars(ui-default-measure4x);
        @include font(16px, 600);
        color: white;
        background: color(accent);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__files {
      padding: 14px;
      border-radius: global-vars(ui-default-measure);
      background: white;

      .title {
        @include font(16px, 600);
        margin-bottom: global-vars(ui-default-measure);
      }

      &-item {
        i {
          margin-right: 6px;
        }
      }
    }

    &__content {
      @include md-down() {
        flex-direction: column;
      }
    }
  }
</style>
