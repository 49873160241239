
  import { defineComponent, computed, ref, Ref } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import firebase from 'firebase';

  import useEmail from '@/composables/use-email';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import useToast from '@/composables/use-toast';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'AdminPanelUsers',
    components: {
      BaseButtonWithSpinner,
      BaseTooltipWrapper,
      AdminPanelHeader
    },
    setup() {
      const { sendInviteEmail } = useEmail();
      const store = useStore();
      const { successToast, errorToast, warningToast } = useToast();
      const { t } = useI18n();

      const inviteEmail: Ref<string> = ref('');
      const showInviteModal: Ref<boolean> = ref(false);
      const inviteLoading: Ref<boolean> = ref(false);

      const users = computed(() => store.state.users);

      const addUserToPendingInvites = async (email: string, query: string) => {
        const payload = {
          email,
          pending: true,
          roles: {
            admin: false,
            gameCreator: false
          },
          id: email,
          inviteUrl: `${process.env.VUE_APP_BASE_URL}/register${query}`
        };

        await db
          .collection(`companies/${store.state.company.id}/users`)
          .doc(inviteEmail.value)
          .set(payload);

        await db
          .collection(`companies`)
          .doc(store.state.company.id)
          .update({
            userEmails: [...store.state.company.userEmails, email]
          });

        setTimeout(() => {
          store.commit('addUserToPendingInvites', payload);
        }, 1000);
      };

      const copy = (url: string): void => {
        // @ts-ignore
        navigator.clipboard.writeText(url);
        successToast(t('common.copiedToClipboard'));
      };

      const handleInvite = async () => {
        if (!inviteEmail.value) {
          return;
        }

        if (store.state.users.some((user: any) => user.email === inviteEmail.value)) {
          warningToast(t('adminPanel.users.alreadyExists'));
          return;
        }

        inviteLoading.value = true;

        // TODO See whether removing token and placing email and company id in the email help with SPAM problem.
        // const tokenPayload = {
        //   inviteEmail: inviteEmail.value,
        //   companyId: store.state.company.id
        // };
        //
        // const token = await store.dispatch('encodeToken', tokenPayload);

        const query = `?inviteEmail=${inviteEmail.value}&companyId=${store.state.company.id}`;

        try {
          await addUserToPendingInvites(inviteEmail.value, query);
          await sendInviteEmail(inviteEmail.value, query);
          setTimeout(() => {
            inviteLoading.value = false;
            showInviteModal.value = false;
            successToast(
              t('adminPanel.users.inviteSent', {
                email: inviteEmail.value
              })
            );
            inviteEmail.value = '';
          }, 1000);
        } catch (e) {
          errorToast(e);
        }
      };

      const removeUser = () => {
        warningToast(t('adminPanel.common.featureNotAvailable'));
      };

      const updateRole = async (id: string, name: string, roles: any) => {
        if (store.state.user.email === id) {
          warningToast(t('adminPanel.users.takeAwayRights'));
          return;
        }

        const updatedRoles = {
          ...roles,
          [name]: !roles[name]
        };

        try {
          await db.doc(`companies/${store.state.company.id}/users/${id}`).update({
            roles: updatedRoles
          });
          successToast(t('adminPanel.users.roleUpdated'));
          store.commit('setUserRoles', {
            id,
            roles: updatedRoles
          });
        } catch (e) {
          errorToast(e);
        }
      };

      return {
        users,
        inviteEmail,
        showInviteModal,
        inviteLoading,
        copy,
        sendInviteEmail,
        handleInvite,
        removeUser,
        updateRole
      };
    }
  });
