
  import { computed, ref, Ref } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  import BoardCardDescription from '@/components/BoardCardDescription.vue';
  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BaseBox from '@/components/common/BaseBox.vue';
  import useToast from '@/composables/use-toast';
  const db = firebase.firestore();

  import { Card, CardUpgradeConfig } from '@/interfaces/businessgame';

  export default {
    name: 'AdminPanelBoardCardUpgrade',
    components: {
      AdminPanelHeader,
      BaseBox,
      BoardCardDescription
    },
    setup() {
      const store = useStore();
      const { successToast, errorToast } = useToast();
      const { t } = useI18n();

      const saveLoading: Ref<boolean> = ref(false);

      const upgradeConfig: Ref<CardUpgradeConfig> = computed(() => ({
        categoryOne: store.state.company.upgradeConfig.categoryOne,
        categoryTwo: store.state.company.upgradeConfig.categoryTwo,
        categoryThree: store.state.company.upgradeConfig.categoryThree,
        categoryFour: store.state.company.upgradeConfig.categoryFour
      }));

      const cardPreviewed: Ref<Card[]> = computed(() => store.state.company.boardConfig[2]);

      const saveCardUpgradeConfig = async () => {
        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            upgradeConfig: upgradeConfig.value
          });

          store.commit('setCardUpgradeConfig', upgradeConfig.value);
          successToast(t(`adminPanel.businessGame.cardUpgrade.saved`));
        } catch (e) {
          errorToast(e);
        }
      };

      return {
        saveCardUpgradeConfig,
        saveLoading,
        upgradeConfig,
        cardPreviewed
      };
    }
  };
