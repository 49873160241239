
  import { useConfirm } from 'primevue/useconfirm';
  import { defineComponent, ComputedRef, Ref, ref, computed } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import useToast from '@/composables/use-toast';
  import { v4 as uuidv4 } from 'uuid';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper.vue';

  import { CardUpgradeConfig, ChanceCard, TemplateItem } from '@/interfaces/businessgame';
  import { useI18n } from 'vue-i18n';
  import { CHANCE_CONFIGS_TEMPLATES } from '@/constants';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'AdminPanelChanceConfig',
    components: {
      BaseButtonWithSpinner,
      AdminPanelHeader,
      BaseTooltipWrapper
    },
    setup() {
      const store = useStore();
      const { successToast, errorToast } = useToast();
      const confirm = useConfirm();
      const { t, locale } = useI18n();

      // templates
      const chanceTemplate: Ref<any> = ref(null);
      const chanceTemplates: Ref<TemplateItem[]> = ref(CHANCE_CONFIGS_TEMPLATES);
      const chanceTemplatesWithCustom: Ref<TemplateItem[]> = computed(() => {
        return [
          ...chanceTemplates.value,
          ...(store.state.company.customChanceCardsTemplates || [])
        ];
      });

      const showNewTemplateModal: Ref<boolean> = ref(false);
      const newTemplateLoading: Ref<boolean> = ref(false);

      // save as new template
      const showSaveAsNewTemplateModal: Ref<boolean> = ref(false);
      const saveAsNewTemplateName: Ref<string> = ref('');
      const saveAsNewTemplateLoading: Ref<boolean> = ref(false);

      const chanceCards: ComputedRef<ChanceCard[]> = computed(
        () => store.state.company.chanceCards
      );

      const upgradeConfig: Ref<CardUpgradeConfig> = computed(() => ({
        categoryOne: store.state.company.upgradeConfig.categoryOne,
        categoryTwo: store.state.company.upgradeConfig.categoryTwo,
        categoryThree: store.state.company.upgradeConfig.categoryThree,
        categoryFour: store.state.company.upgradeConfig.categoryFour
      }));

      const cardEditedIndex: Ref<number> = ref(0);

      const cardEdited: ComputedRef<ChanceCard> = computed(() => {
        return chanceCards.value?.[cardEditedIndex.value];
      });

      const methodOptions: Ref<{ value: string; label: string }[]> = ref([
        {
          value: 'addRoundToCurrentPlayer',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.addRoundToCurrentPlayer')
        },
        {
          value: 'addMoneyToCurrentPlayer',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.addMoneyToCurrentPlayer')
        },
        {
          value: 'removeMoneyFromCurrentPlayer',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.removeMoneyFromCurrentPlayer')
        },
        {
          value: 'addToPayroll',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.addToPayroll')
        },
        {
          value: 'removeFromPayroll',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.removeFromPayroll')
        },
        {
          value: 'removeMoneyFromCurrentPlayerIfConditions',
          label: t(
            'adminPanel.businessGame.chanceCards.actionsLabels.removeMoneyFromCurrentPlayerIfConditions'
          )
        },
        {
          value: 'addMoneyToPayrollIfConditions',
          label: t(
            'adminPanel.businessGame.chanceCards.actionsLabels.addMoneyToPayrollIfConditions'
          )
        },
        {
          value: 'addMoneyToCurrentPlayerIfConditions',
          label: t(
            'adminPanel.businessGame.chanceCards.actionsLabels.addMoneyToCurrentPlayerIfConditions'
          )
        },
        {
          value: 'changeTeamMembers',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.changeTeamMembers')
        }
      ]);

      const fromToOptions: Ref<{ value: string; label: string }[]> = computed(() => {
        return [
          {
            value: 'categoryOne',
            // @ts-ignore
            label: upgradeConfig.value?.categoryOne[locale.value]
          },
          {
            value: 'categoryTwo',
            // @ts-ignore
            label: upgradeConfig.value?.categoryTwo[locale.value]
          },
          {
            value: 'categoryThree',
            // @ts-ignore
            label: upgradeConfig.value?.categoryThree[locale.value]
          },
          {
            value: 'categoryFour',
            // @ts-ignore
            label: upgradeConfig.value?.categoryFour[locale.value]
          }
        ];
      });

      const typeOptions: Ref<{ value: string; label: string }[]> = computed(() => {
        return [
          // {
          //   value: 'teamFieldMatch',
          //   label: t(
          //     'adminPanel.businessGame.chanceCards.typeOptions.teamFieldMatch'
          //   )
          // },
          // {
          //   value: 'teamFieldDontMatch',
          //   label: t(
          //     'adminPanel.businessGame.chanceCards.typeOptions.teamFieldDontMatch'
          //   )
          // },
          {
            value: 'cardsMax',
            label: t('adminPanel.businessGame.chanceCards.typeOptions.cardsMax')
          }
        ];
      });

      const whatToShowConfig: Ref = ref({
        addMoneyToCurrentPlayer: 'number',
        addRoundToCurrentPlayer: 'number',
        removeMoneyFromCurrentPlayer: 'number',
        addToPayroll: 'number',
        removeFromPayroll: 'number',
        removeMoneyFromCurrentPlayerIfConditions: 'conditions',
        addMoneyToPayrollIfConditions: 'conditions',
        addMoneyToCurrentPlayerIfConditions: 'conditions',
        changeTeamMembers: 'fromToMax'
      });

      const brandColor = computed(() => store.state.company.brandColor);

      const editCard = (id: string) => {
        cardEditedIndex.value = chanceCards.value.findIndex((c: ChanceCard) => c.id === id);
      };

      const editCardFromRow = ({ data }: { data: ChanceCard }) => {
        cardEditedIndex.value = chanceCards.value.findIndex((c: ChanceCard) => c.id === data.id);
      };

      const addNew = () => {
        const id = uuidv4();
        store.commit('addNewChanceCard', {
          id,
          type: 'chance',
          descriptionEn: '',
          descriptionPl: ''
        });
        editCard(id);
      };

      const handleSaveChanceCards = async () => {
        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            chanceCards: chanceCards.value
          });
          store.commit('setChanceCards', chanceCards.value);
          successToast(t('adminPanel.businessGame.chanceCards.updated'));
        } catch (e) {
          errorToast(e);
        }
      };

      const deleteChanceCard = (id: string) => {
        confirm.require({
          message: t('adminPanel.businessGame.chanceCards.wantToProceed'),
          header: t('adminPanel.businessGame.chanceCards.confirmation'),
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            try {
              const cards = chanceCards.value.filter((card: ChanceCard) => card.id !== id);
              await db.doc(`companies/${store.state.company.id}`).update({
                chanceCards: cards
              });
              store.commit('setChanceCards', cards);
              successToast(t('adminPanel.businessGame.chanceCards.removed'));
            } catch (e) {
              errorToast(e);
            }
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      };

      const changeCardEditedMethod = (value: string) => {
        let payload: any = null;

        if (whatToShowConfig.value[value] === 'number') {
          payload = null;
        } else if (whatToShowConfig.value[value] === 'conditions') {
          payload = {
            type: null,
            value: null,
            money: null
          };
        } else if (whatToShowConfig.value[value] === 'fromToMax') {
          payload = {
            from: null,
            to: null,
            max: null
          };
        }

        store.commit('setChanceCard', {
          id: cardEdited.value?.id,
          method: value,
          payload
        });
      };

      const getChanceTemplate = async (templateName: string) => {
        return await db
          .doc(`chanceCardsConfigs/${templateName}`)
          .get()
          .then(doc => doc.data());
      };

      const handleSelectNewTemplate = async () => {
        if (!chanceTemplate.value) {
          return;
        }

        newTemplateLoading.value = true;

        try {
          if (CHANCE_CONFIGS_TEMPLATES.some(template => template.value === chanceTemplate.value)) {
            const response = await getChanceTemplate(chanceTemplate.value);
            await db.doc(`companies/${store.state.company.id}`).update({
              cards: response?.cards
            });
            store.commit('setChanceCards', response?.cards);
          } else {
            await db.doc(`companies/${store.state.company.id}`).update({
              cards: chanceTemplate.value?.cards
            });
            store.commit('setChanceCards', chanceTemplate.value?.cards);
          }
          successToast(t('adminPanel.businessGame.chanceCards.newTemplateSelected'));
        } catch (e) {
          errorToast(e);
        } finally {
          // eslint-disable-next-line require-atomic-updates
          chanceTemplate.value = null;
          newTemplateLoading.value = false;
          showNewTemplateModal.value = false;
        }
      };

      const handleSaveAsNewTemplate = async () => {
        if (!saveAsNewTemplateName.value) {
          return;
        }

        saveAsNewTemplateLoading.value = true;

        try {
          const currentTemplates = store.state.company.customChanceCardsTemplates || [];
          await db.doc(`companies/${store.state.company.id}`).update({
            customChanceCardsTemplates: [
              ...currentTemplates,
              {
                label: saveAsNewTemplateName.value,
                value: {
                  cards: chanceCards.value
                }
              }
            ]
          });
          store.commit('addToCustomChanceCardsTemplates', {
            label: saveAsNewTemplateName.value,
            value: {
              cards: chanceCards.value
            }
          });
          successToast(
            t('adminPanel.businessGame.chanceCards.customTemplateAdded', {
              name: saveAsNewTemplateName.value
            })
          );
        } catch (e) {
          errorToast(e);
        } finally {
          // eslint-disable-next-line require-atomic-updates
          saveAsNewTemplateName.value = '';
          saveAsNewTemplateLoading.value = false;
          showSaveAsNewTemplateModal.value = false;
        }
      };

      return {
        chanceCards,
        cardEdited,
        methodOptions,
        fromToOptions,
        whatToShowConfig,
        typeOptions,
        brandColor,
        editCard,
        editCardFromRow,
        handleSaveChanceCards,
        addNew,
        deleteChanceCard,
        changeCardEditedMethod,
        chanceTemplate,
        chanceTemplates,
        chanceTemplatesWithCustom,
        showNewTemplateModal,
        newTemplateLoading,
        showSaveAsNewTemplateModal,
        saveAsNewTemplateName,
        saveAsNewTemplateLoading,
        handleSelectNewTemplate,
        handleSaveAsNewTemplate
      };
    }
  });
