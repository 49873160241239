
  import { computed, ref, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BaseBox from '@/components/common/BaseBox.vue';
  import useToast from '@/composables/use-toast';
  import { openTriviaDatabaseCategories } from '@/helpers/funteambidding/initials';

  import { FunTeamBiddingCategory, FunTeamBiddingSettings } from '@/interfaces/funteambidding';
  import { useI18n } from 'vue-i18n';

  const db = firebase.firestore();

  export default {
    name: 'AdminPanelFunTeamBiddingSettings',
    components: {
      AdminPanelHeader,
      BaseBox
    },
    setup() {
      const store = useStore();
      const { successToast, errorToast } = useToast();
      const { t } = useI18n();

      const saveLoading: Ref<boolean> = ref(false);

      const funTeamBiddingSettings: ComputedRef<FunTeamBiddingSettings> = computed(
        () => store.state.company.funTeamBiddingSettings
      );

      const saveTeamBiddingConfigSettings = async () => {
        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            funTeamBiddingSettings: {
              categories: funTeamBiddingSettings.value.categories,
              teamMoneyForStart: Number(funTeamBiddingSettings.value.teamMoneyForStart),
              moneyTakenForStartOfBidding: Number(
                funTeamBiddingSettings.value.moneyTakenForStartOfBidding
              ),
              timeAfterBidInSeconds: Number(funTeamBiddingSettings.value.timeAfterBidInSeconds),
              maxDebt: Number(funTeamBiddingSettings.value.maxDebt)
            }
          });

          store.commit('saveFunTeamBiddingSettings', funTeamBiddingSettings.value);
          successToast(t('success.saved'));
        } catch (e) {
          errorToast(e);
        }
      };

      const checkIfCategorySelected = (id: string) => {
        return funTeamBiddingSettings.value.categories.some(cat => cat.id === id);
      };

      const handleToggleCategory = (category: FunTeamBiddingCategory) => {
        if (checkIfCategorySelected(category.id)) {
          store.commit('removeFromFunTeamBiddingCategories', category);
        } else {
          store.commit('addToFunTeamBiddingCategories', category);
        }
      };

      return {
        saveTeamBiddingConfigSettings,
        handleToggleCategory,
        saveLoading,
        funTeamBiddingSettings,
        openTriviaDatabaseCategories,
        checkIfCategorySelected
      };
    }
  };
