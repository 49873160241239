
  import { computed, defineComponent, ref, Ref } from 'vue';
  import { useStore } from 'vuex';
  import firebase from 'firebase';
  import { useI18n } from 'vue-i18n';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BoardOutside from '@/components/BoardOutside.vue';
  import BoardCard from '@/components/BoardCard.vue';
  import BaseBrandingItem from '@/components/common/BaseBrandingItem.vue';
  import BoardCardDescription from '@/components/BoardCardDescription.vue';
  import { Card, CardUpgradeConfig, TemplateItem } from '@/interfaces/businessgame';
  import { BOARD_CONFIGS_TEMPLATES, BUYABLE_CARDS, CARD_TYPES } from '@/constants';
  import BaseTeamCard from '@/components/BaseTeamCard.vue';
  import BaseChanceCard from '@/components/BaseChanceCard.vue';
  import BaseOfficeCard from '@/components/BaseOfficeCard.vue';
  import BaseZusCard from '@/components/BaseZusCard.vue';
  import BaseCornerCard from '@/components/BaseCornerCard.vue';
  import BaseIndividualCard from '@/components/BaseIndividualCard.vue';
  import useToast from '@/composables/use-toast';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'AdminPanelBoardConfig',
    components: {
      BaseButtonWithSpinner,
      AdminPanelHeader,
      BoardOutside,
      BoardCard,
      BaseBrandingItem,
      BoardCardDescription
    },
    setup() {
      const BUYABLE: Ref<string[]> = ref(BUYABLE_CARDS);

      const store = useStore();
      const { successToast, errorToast } = useToast();
      const { t } = useI18n();

      const boardPreviewVisible: Ref<boolean> = ref(false);
      const cardPreviewVisible: Ref<boolean> = ref(false);
      const boardConfig: Ref<Card[]> = computed(() => store.state.company.boardConfig);
      const upgradeConfig: Ref<CardUpgradeConfig> = computed(() => ({
        categoryOne: store.state.company.upgradeConfig.categoryOne,
        categoryTwo: store.state.company.upgradeConfig.categoryTwo,
        categoryThree: store.state.company.upgradeConfig.categoryThree,
        categoryFour: store.state.company.upgradeConfig.categoryFour
      }));

      const cardEdited: Ref<undefined | Card> = ref(boardConfig.value[0]);
      const cardPreviewed: Ref<undefined | Card> = ref(undefined);

      // templates
      const boardTemplate: Ref<any> = ref('');
      const boardTemplates: Ref<TemplateItem[]> = ref(BOARD_CONFIGS_TEMPLATES);
      const boardTemplatesWithCustom: Ref<TemplateItem[]> = computed(() => {
        return [...boardTemplates.value, ...store.state.company.customBoardConfigTemplates];
      });

      const showNewTemplateModal: Ref<boolean> = ref(false);
      const newTemplateLoading: Ref<boolean> = ref(false);

      // save as new template
      const showSaveAsNewTemplateModal: Ref<boolean> = ref(false);
      const saveAsNewTemplateName: Ref<string> = ref('');
      const saveAsNewTemplateLoading: Ref<boolean> = ref(false);

      const boardCardComponent = computed(() => {
        switch (cardPreviewed.value?.type) {
          case CARD_TYPES.TEAM:
            return BaseTeamCard;
          case CARD_TYPES.CHANCE:
            return BaseChanceCard;
          case CARD_TYPES.OFFICE:
            return BaseOfficeCard;
          case CARD_TYPES.ZUS:
            return BaseZusCard;
          case CARD_TYPES.CORNER:
            return BaseCornerCard;
          case CARD_TYPES.INDIVIDUAL:
            return BaseIndividualCard;
          default:
            return BaseTeamCard;
        }
      });

      const previewBoardConfig = () => (boardPreviewVisible.value = true);

      const updateColorInEditedCard = (color: string) => {
        if (!color) {
          return;
        }

        return cardEdited.value ? (cardEdited.value.color = color) : null;
      };

      const editCard = (id: number) =>
        (cardEdited.value = boardConfig.value.find((c: Card) => c.id === id));

      const editCardFromRow = ({ data }: { data: Card }) => {
        cardEdited.value = boardConfig.value.find((c: Card) => c.id === data.id);
      };

      const previewCard = (id: number) => {
        cardPreviewed.value = boardConfig.value.find((c: Card) => c.id === id);
        cardPreviewVisible.value = true;
      };

      const getBoardConfig = async (configName: string) => {
        return await db
          .doc(`boardConfigs/${configName}`)
          .get()
          .then(doc => doc.data());
      };

      const handleSelectNewTemplate = async () => {
        if (!boardTemplate.value) {
          return;
        }

        newTemplateLoading.value = true;

        try {
          if (BOARD_CONFIGS_TEMPLATES.some(template => template.value === boardTemplate.value)) {
            const response = await getBoardConfig(boardTemplate.value);
            await db.doc(`companies/${store.state.company.id}`).update({
              boardConfig: response?.config,
              upgradeConfig: response?.upgradeConfig
            });
            store.commit('setBoardConfig', response?.config);
            store.commit('setCardUpgradeConfig', response?.upgradeConfig);
          } else {
            await db.doc(`companies/${store.state.company.id}`).update({
              boardConfig: boardTemplate.value?.boardConfig,
              upgradeConfig: boardTemplate.value?.upgradeConfig
            });
            store.commit('setBoardConfig', boardTemplate.value?.boardConfig);
            store.commit('setCardUpgradeConfig', boardTemplate.value?.upgradeConfig);
          }
          successToast(t('adminPanel.businessGame.boardConfig.newTemplateSelected'));
        } catch (e) {
          errorToast(e);
        } finally {
          // eslint-disable-next-line require-atomic-updates
          boardTemplate.value = '';
          newTemplateLoading.value = false;
          showNewTemplateModal.value = false;
        }
      };

      const handleSaveAsNewTemplate = async () => {
        if (!saveAsNewTemplateName.value) {
          return;
        }

        saveAsNewTemplateLoading.value = true;

        try {
          const currentTemplates = store.state.company.customBoardConfigTemplates || [];
          await db.doc(`companies/${store.state.company.id}`).update({
            customBoardConfigTemplates: [
              ...currentTemplates,
              {
                label: saveAsNewTemplateName.value,
                value: {
                  boardConfig: boardConfig.value,
                  upgradeConfig: upgradeConfig.value
                }
              }
            ]
          });
          store.commit('addToCustomBoardConfigTemplates', {
            label: saveAsNewTemplateName.value,
            value: {
              boardConfig: boardConfig.value,
              upgradeConfig: upgradeConfig.value
            }
          });
          successToast(
            t('adminPanel.businessGame.boardConfig.customTemplateAdded', {
              name: saveAsNewTemplateName.value
            })
          );
        } catch (e) {
          errorToast(e);
        } finally {
          // eslint-disable-next-line require-atomic-updates
          saveAsNewTemplateName.value = '';
          saveAsNewTemplateLoading.value = false;
          showSaveAsNewTemplateModal.value = false;
        }
      };

      const handleSaveBoard = async () => {
        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            boardConfig: boardConfig.value
          });
          successToast(t('adminPanel.businessGame.boardConfig.boardUpdated'));
        } catch (e) {
          errorToast(e);
        }
      };

      return {
        BUYABLE,
        boardConfig,
        boardPreviewVisible,
        cardPreviewVisible,
        cardEdited,
        cardPreviewed,
        boardCardComponent,
        boardTemplate,
        boardTemplates,
        showNewTemplateModal,
        newTemplateLoading,
        upgradeConfig,
        showSaveAsNewTemplateModal,
        saveAsNewTemplateName,
        saveAsNewTemplateLoading,
        boardTemplatesWithCustom,
        updateColorInEditedCard,
        handleSaveBoard,
        handleSelectNewTemplate,
        previewBoardConfig,
        editCard,
        editCardFromRow,
        previewCard,
        handleSaveAsNewTemplate
      };
    }
  });
