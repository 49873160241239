
  import { computed, defineComponent } from 'vue';

  import useFirebase from '@/composables/use-firebase';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { GAME_NAMES, GAME_NAMES_HUMANIZED } from '@/helpers';

  export default defineComponent({
    name: 'AdminPanelNavigation',
    setup() {
      const store = useStore();
      const { logout, getCurrentUser } = useFirebase();
      const { currentRoute } = useRouter();
      const { t } = useI18n();

      const user = getCurrentUser();
      const company = computed(() => store.state.company);
      const gamesInPackage = computed(() => store.state.company.package?.gamesInPackage || {});

      const navigationItems = computed(() =>
        [
          {
            type: 'item',
            icon: 'pi pi-home',
            path: '/dashboard',
            label: t('adminPanel.navigation.dashboard')
          },
          { type: 'heading', label: t('adminPanel.navigation.games') },
          {
            type: 'item',
            image: require('@/assets/logos/business-game/logo-white-tile.svg'),
            game: true,
            path: '/dashboard/funbusinessgame',
            gameName: GAME_NAMES.FUN_BUSINESS_GAME,
            label: GAME_NAMES_HUMANIZED.FUN_BUSINESS_GAME,
            // @ts-ignore
            submenuHidden: !currentRoute.value?.name?.includes('businessgame'),
            submenu: [
              {
                type: 'item',
                icon: 'pi pi-money-bill',
                path: '/dashboard/funbusinessgame/games',
                label: t('adminPanel.navigation.games')
              },
              {
                type: 'item',
                icon: 'pi pi-sliders-h',
                path: '/dashboard/funbusinessgame/board-config',
                label: t('adminPanel.navigation.listOfCards')
              },
              {
                type: 'item',
                icon: 'pi pi-sliders-h',
                path: '/dashboard/funbusinessgame/card-upgrade',
                label: t('adminPanel.navigation.cardsUpgradeConfig')
              },
              {
                type: 'item',
                icon: 'pi pi-credit-card',
                path: '/dashboard/funbusinessgame/chance-config',
                label: t('adminPanel.navigation.chanceCards')
              }
            ]
          },
          {
            type: 'item',
            game: true,
            image: require('@/assets/logos/logo-funteambidding.svg'),
            path: '/dashboard/funteambidding',
            gameName: GAME_NAMES.FUN_TEAM_BIDDING,
            label: GAME_NAMES_HUMANIZED.FUN_TEAM_BIDDING,
            // @ts-ignore
            submenuHidden: !currentRoute.value?.name?.includes('funteambidding'),
            submenu: [
              {
                type: 'item',
                icon: 'pi pi-money-bill',
                path: '/dashboard/funteambidding/games',
                label: t('adminPanel.navigation.games')
              },
              {
                type: 'item',
                icon: 'pi pi-cog',
                path: '/dashboard/funteambidding/custom-questions',
                label: t('adminPanel.navigation.customQuestions')
              },
              {
                type: 'item',
                icon: 'pi pi-cog',
                path: '/dashboard/funteambidding/settings',
                label: t('adminPanel.navigation.settings')
              }
            ]
          },
          {
            type: 'item',
            image: require('@/assets/logos/logo-funicebreaker.svg'),
            path: '/dashboard/funicebreaker',
            game: true,
            gameName: GAME_NAMES.FUN_ICE_BREAKER,
            label: GAME_NAMES_HUMANIZED.FUN_ICE_BREAKER,
            // @ts-ignore
            submenuHidden: !currentRoute.value?.name?.includes('funicebreaker'),
            submenu: [
              {
                type: 'item',
                icon: 'pi pi-money-bill',
                path: '/dashboard/funicebreaker/games',
                label: t('adminPanel.navigation.games')
              },
              {
                type: 'item',
                icon: 'pi pi-cog',
                path: '/dashboard/funicebreaker/custom-questions',
                label: t('adminPanel.navigation.customQuestions')
              },
              {
                type: 'item',
                icon: 'pi pi-cog',
                path: '/dashboard/funicebreaker/settings',
                label: t('adminPanel.navigation.settings')
              }
              // {
              //   type: 'item',
              //   icon: 'pi pi-cog',
              //   path: '/dashboard/funicebreaker/leaderboard',
              //   label: t('adminPanel.navigation.leaderboard')
              // }
            ]
          },
          {
            type: 'item',
            image: require('@/assets/logos/logo-funteamclues.svg'),
            path: '/dashboard/funteamclues',
            game: true,
            gameName: GAME_NAMES.FUN_TEAM_CLUES,
            label: GAME_NAMES_HUMANIZED.FUN_TEAM_CLUES,
            // @ts-ignore
            submenuHidden: !currentRoute.value?.name?.includes('funteamclues'),
            submenu: [
              {
                type: 'item',
                icon: 'pi pi-money-bill',
                path: '/dashboard/funteamclues/games',
                label: t('adminPanel.navigation.games')
              }
              // {
              //   type: 'item',
              //   icon: 'pi pi-cog',
              //   path: '/dashboard/funicebreaker/leaderboard',
              //   label: t('adminPanel.navigation.leaderboard')
              // }
            ]
          },
          {
            type: 'heading',
            label: t('adminPanel.navigation.companySettings')
          },
          {
            type: 'item',
            icon: 'pi pi-flag',
            path: '/dashboard/branding',
            label: t('adminPanel.navigation.infoBranding')
          },
          {
            type: 'item',
            icon: 'pi pi-users',
            path: '/dashboard/users',
            label: t('adminPanel.navigation.users')
          },
          // {
          //   type: 'item',
          //   icon: 'pi pi-cog',
          //   path: '/dashboard/referral-program',
          //   label: t('adminPanel.navigation.getFreeCoins')
          // },
          {
            type: 'item',
            icon: 'pi pi-question-circle',
            path: '/dashboard/help-and-feedback',
            label: t('adminPanel.navigation.help')
          }
        ].filter(item => {
          return item.game ? Object.keys(gamesInPackage.value).includes(item.gameName) : true;
        })
      );

      return {
        logout,
        user,
        navigationItems,
        company
      };
    }
  });
