
  import { useConfirm } from 'primevue/useconfirm';
  import { defineComponent, ComputedRef, Ref, ref, computed } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { v4 as uuidv4 } from 'uuid';
  import { useRouter } from 'vue-router';

  import useToast from '@/composables/use-toast';
  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper.vue';

  import {
    FUN_ICE_BREAKER_QUESTION_TYPES,
    FunIceBreakerQuestionConfig
  } from '@/interfaces/funicebreaker';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'AdminPanelFunIceBreakerCustomQuestions',
    components: {
      AdminPanelHeader,
      BaseTooltipWrapper
    },
    data() {
      return {
        FUN_ICE_BREAKER_QUESTION_TYPES: FUN_ICE_BREAKER_QUESTION_TYPES
      };
    },
    setup() {
      const store = useStore();
      const { successToast, errorToast, warningToast } = useToast();
      const confirm = useConfirm();
      const { t } = useI18n();
      const { push } = useRouter();
      const selectedQuestions: Ref<FunIceBreakerQuestionConfig[]> = ref([]);

      const customQuestions: ComputedRef<FunIceBreakerQuestionConfig[]> = computed(
        () => store.state.company.funIceBreakerCustomQuestions
      );

      const questionEditedIndex: Ref<number> = ref(0);

      const questionEdited: ComputedRef<FunIceBreakerQuestionConfig> = computed(
        () => customQuestions.value[questionEditedIndex.value]
      );

      const typeOptions: Ref<{ value: string; label: string }[]> = ref([
        {
          value: FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE,
          label: t(`funIceBreaker.customQuestions.types.${FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE}`)
        },
        {
          value: FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE,
          label: t(`funIceBreaker.customQuestions.types.${FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE}`)
        }
      ]);

      const editQuestion = (id: string) => {
        questionEditedIndex.value = customQuestions.value.findIndex((q: any) => q.id === id);
      };

      const editQuestionFromRow = ({ data }: { data: any }) => {
        questionEditedIndex.value = customQuestions.value.findIndex((q: any) => q.id === data.id);
      };

      const addNew = () => {
        // make id more reliable
        const id = uuidv4();
        const payload: FunIceBreakerQuestionConfig = {
          id,
          textPl: '',
          textEn: '',
          type: FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
        };
        store.commit('addNewIceBreakerCustomQuestions', payload);
        editQuestion(id);
      };

      const startNewGameWithSelectedQuestions = () => {
        if (selectedQuestions.value.length > 15) {
          warningToast(t('funIceBreaker.customQuestions.tooMany'));
          return;
        }

        if (selectedQuestions.value.length < 3) {
          warningToast(t('funIceBreaker.customQuestions.tooFew'));
          return;
        }

        push({
          name: 'funicebreaker-new-game',
          query: {
            questions: selectedQuestions.value.map(item => item.id).join(',')
          }
        });
      };

      const handleSaveCustomQuestion = async () => {
        if (
          customQuestions.value.some(question => {
            return !question.textEn && !question.textPl;
          })
        ) {
          warningToast(t('funIceBreaker.customQuestions.questionWrongConfig'), 6000);
        }

        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            funIceBreakerCustomQuestions: customQuestions.value
          });
          store.commit('setIceBreakerCustomQuestions', customQuestions.value);
          successToast(t('funIceBreaker.customQuestions.updated'));
        } catch (e) {
          errorToast(e);
        }
      };

      const deleteCustomQuestion = (id: string) => {
        confirm.require({
          message: t('funIceBreaker.customQuestions.wantToProceed'),
          header: t('funIceBreaker.customQuestions.confirmation'),
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            try {
              const questions = customQuestions.value.filter(q => q.id !== id);
              await db.doc(`companies/${store.state.company.id}`).update({
                funIceBreakerCustomQuestions: questions
              });
              store.commit('setIceBreakerCustomQuestions', questions);
              successToast(t('funIceBreaker.customQuestions.removed'));
            } catch (e) {
              errorToast(e);
            }
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      };

      return {
        customQuestions,
        questionEdited,
        typeOptions,
        selectedQuestions,
        editQuestion,
        editQuestionFromRow,
        handleSaveCustomQuestion,
        addNew,
        deleteCustomQuestion,
        startNewGameWithSelectedQuestions
      };
    }
  });
