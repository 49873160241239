<template>
  <AdminPanelActiveGamesCommon :game-name="gameName" :firebase-key="firebaseKey" />
</template>

<script>
  import AdminPanelActiveGamesCommon from '@/components/admin-panel/AdminPanelActiveGamesCommon';

  import { GAME_NAMES } from '@/helpers';

  export default {
    name: 'AdminPanelFunTeamCluesActiveGames',
    components: {
      AdminPanelActiveGamesCommon
    },
    data() {
      return {
        gameName: GAME_NAMES.FUN_TEAM_CLUES,
        firebaseKey: 'funTeamCluesGames'
      };
    }
  };
</script>
