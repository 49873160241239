
  import { defineComponent, onMounted, ref } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import AdminPanelNavigation from '@/components/admin-panel/AdminPanelNavigation.vue';
  import useToast from '@/composables/use-toast';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'AdminPanel',
    components: { AdminPanelNavigation },
    setup() {
      const store = useStore();
      const { errorToast } = useToast();
      const { push } = useRouter();
      const { t, locale } = useI18n();

      const loading = ref(false);
      const accessLoading = ref(false);

      onMounted(async () => {
        loading.value = true;
        accessLoading.value = true;
        try {
          const companyId = await getCurrentCompanyId();
          await checkIfUserIsAdmin(companyId);
          accessLoading.value = false;
          await getCurrentCompany(companyId);
          await getGames();
          await getUsers();
        } catch {
          errorToast(t('errors.somethingWentWrong'));
        }

        locale.value = store.state.company.signupLanguage || 'en';
        loading.value = false;
      });

      const getCurrentCompanyId = async () => {
        return await db
          .collection('companies')
          .where('userEmails', 'array-contains', store.state.user?.email)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push(doc.id);
            });

            return resultArray[0];
          })
          .catch(e => {
            errorToast(e);
          });
      };

      const checkIfUserIsAdmin = async (companyId: string) => {
        await db
          .doc(`companies/${companyId}/users/${store.state.user.email}`)
          .get()
          .then(doc => {
            const data = doc.data();

            if (!data?.roles.admin) {
              throw new Error();
            }
          })
          .catch(() => {
            push('/');
            errorToast(t('errors.noPermissions'));
          });
      };

      const getCurrentCompany = async (companyId: string | null = null) => {
        const company = await db
          .doc(`companies/${companyId}`)
          .get()
          .then(doc => {
            return { id: doc.id, ...doc.data() };
          })
          .catch(e => errorToast(e));

        store.commit('setCompany', company);
      };

      const getGames = async () => {
        const games = await db
          .collection(`companies/${store.state.company.id}/games`)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          });

        const funDiceGames = await db
          .collection(`companies/${store.state.company.id}/funDiceGames`)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          });

        const funIceBreakerGames = await db
          .collection(`companies/${store.state.company.id}/funIceBreakerGames`)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          });

        const funTeamBiddingGames = await db
          .collection(`companies/${store.state.company.id}/funTeamBiddingGames`)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          });

        const funTeamCluesGames = await db
          .collection(`companies/${store.state.company.id}/funTeamCluesGames`)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          });

        store.commit('setCompanyGames', {
          games,
          funDiceGames,
          funTeamBiddingGames,
          funIceBreakerGames,
          funTeamCluesGames
        });
      };

      const getUsers = async () => {
        const users = await db
          .collection(`companies/${store.state.company.id}/users`)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          });

        store.commit('setCompanyUsers', users);
      };

      return {
        loading,
        accessLoading
      };
    }
  });
