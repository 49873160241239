
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { format, formatDistanceToNowStrict, isFuture } from 'date-fns';
  import { useI18n } from 'vue-i18n';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import useToast from '@/composables/use-toast';
  import { enGB, pl } from 'date-fns/locale';

  export default defineComponent({
    name: 'AdminPanelActiveGamesCommon',
    props: {
      firebaseKey: {
        type: String,
        default: ''
      },
      gameName: {
        type: String,
        default: ''
      }
    },
    components: {
      AdminPanelHeader
    },
    setup(props) {
      const store = useStore();
      const { successToast } = useToast();
      const { t, locale } = useI18n();

      const games = computed(() => {
        return store.state[props.firebaseKey];
      });
      const companyId = computed(() => store.state.company.id);
      const packageOfGames = computed(() => store.state.company.package);

      const gamesLeft = computed(() => packageOfGames.value?.gamesLeft[props.gameName]);
      const gameNameForLinks = computed(() => props.gameName.toLowerCase().replaceAll('_', ''));

      const getLink = (id: string) =>
        `https://${window.location.hostname}/${gameNameForLinks.value}/${companyId.value}/${id}`;

      const formatDate = (date: any) =>
        format(date.seconds ? date.seconds : new Date(date), 'dd MMM yyyy, HH:mm', {
          locale: locale.value === 'pl' ? pl : enGB
        });

      const getTimeLeft = (timestamp: number) => {
        if (!isFuture(timestamp)) {
          return '--';
        }

        return formatDistanceToNowStrict(timestamp, {
          locale: locale.value === 'pl' ? pl : enGB
        });
      };

      const copy = (id: string): void => {
        navigator.clipboard.writeText(
          `https://${window.location.hostname}/${gameNameForLinks.value}/${companyId.value}/${id}`
        );
        successToast(t('common.copiedToClipboard'));
      };

      return {
        games,
        packageOfGames,
        gamesLeft,
        copy,
        getLink,
        formatDate,
        getTimeLeft,
        gameNameForLinks,
        companyId
      };
    }
  });
