
  import { useConfirm } from 'primevue/useconfirm';
  import { defineComponent, ComputedRef, Ref, ref, computed } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { v4 as uuidv4 } from 'uuid';
  import { useI18n } from 'vue-i18n';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import useToast from '@/composables/use-toast';
  import { FunTeamBiddingActiveQuestion } from '@/interfaces/funteambidding';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'AdminPanelFunTeamBiddingCustomQuestions',
    components: {
      AdminPanelHeader,
      BaseButtonWithSpinner
    },
    setup() {
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const confirm = useConfirm();
      const { t } = useI18n();

      const customQuestions: ComputedRef<FunTeamBiddingActiveQuestion[]> = computed(
        () => store.state.company.funTeamBiddingCustomQuestions
      );

      const presets: ComputedRef<any[]> = computed(() => store.state.company.funTeamBiddingPresets);

      const questionEditedIndex: Ref<number> = ref(0);
      const showNewPresetModal: Ref<boolean> = ref(false);
      const newPresetLoading: Ref<boolean> = ref(false);
      const newPresetName: Ref<string> = ref('');

      const questionEdited: ComputedRef<FunTeamBiddingActiveQuestion> = computed(
        () => customQuestions.value[questionEditedIndex.value]
      );

      const typeOptions: Ref<{ value: string; label: string }[]> = ref([
        {
          value: 'multiple',
          label: t('funTeamBidding.customQuestions.multiple')
        },
        {
          value: 'yesNo',
          label: t('funTeamBidding.customQuestions.yesNo')
        }
      ]);

      const selectedQuestions: Ref<FunTeamBiddingActiveQuestion[]> = ref([]);

      const editQuestion = (id: string) => {
        questionEditedIndex.value = customQuestions.value.findIndex((q: any) => q.id === id);
      };

      const editQuestionFromRow = ({ data }: { data: any }) => {
        questionEditedIndex.value = customQuestions.value.findIndex((q: any) => q.id === data.id);
      };

      const addNew = () => {
        // make id more reliable
        const id = uuidv4();
        store.commit('addNewTeamBiddingCustomQuestions', {
          id,
          type: 'multiple',
          textPl: '',
          textEn: '',
          options: ['', '', '', ''],
          validAnswer: ''
        });
        editQuestion(id);
      };

      const handleSetOptionsForQuestion = ({ value }: { value: string }) => {
        if (value === 'multiple') {
          customQuestions.value[questionEditedIndex.value].options = ['', '', '', ''];
        } else if (value === 'yesNo') {
          customQuestions.value[questionEditedIndex.value].options = [
            t('common.yes'),
            t('common.no')
          ];
        }
      };

      const checkWarningsForSaving = () => {
        const noTextInSomeQuestion = customQuestions.value.some(question => {
          return !question.textEn && !question.textPl;
        });

        const wrongOptions = customQuestions.value.some(question => {
          return question.options.filter(o => o).length < 2;
        });

        const noValidOptionSelected = customQuestions.value.some(question => {
          return !question.validAnswer;
        });

        const noTextInSomeQuestionText = t(
          'funTeamBidding.customQuestions.noTextInSomeQuestionText'
        );
        const wrongOptionsText = t('funTeamBidding.customQuestions.wrongOptionsText');
        const noValidOptionSelectedText = t(
          'funTeamBidding.customQuestions.noValidOptionSelectedText'
        );

        if (noTextInSomeQuestion || wrongOptions || noValidOptionSelected) {
          warningToast(
            `${t('funTeamBidding.customQuestions.notValidAnswer.0')} \n\n${
              noTextInSomeQuestion ? `${noTextInSomeQuestionText}\n\n` : ''
            } ${wrongOptions ? `${wrongOptionsText}\n\n` : ''} ${
              noValidOptionSelected ? `${noValidOptionSelectedText}\n\n` : ''
            } ${t('funTeamBidding.customQuestions.notValidAnswer.1')}`,
            12000
          );
        }
      };

      const handleSaveCustomQuestion = async () => {
        checkWarningsForSaving();

        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            funTeamBiddingCustomQuestions: customQuestions.value
          });
          store.commit('setTeamBiddingCustomQuestions', customQuestions.value);
          successToast(t('funTeamBidding.customQuestions.updated'));
        } catch (e) {
          errorToast(e);
        }
      };

      const deleteCustomQuestion = (id: string) => {
        confirm.require({
          message: t('funTeamBidding.customQuestions.wantToProceed'),
          header: t('funTeamBidding.customQuestions.confirmation'),
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            try {
              const questions = customQuestions.value.filter(q => q.id !== id);
              await db.doc(`companies/${store.state.company.id}`).update({
                funTeamBiddingCustomQuestions: questions
              });
              store.commit('setTeamBiddingCustomQuestions', questions);
              successToast(t('funTeamBidding.customQuestions.removed'));
            } catch (e) {
              errorToast(e);
            }
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      };

      const addNewPreset = async () => {
        if (!newPresetName.value) {
          return;
        }

        newPresetLoading.value = true;

        const currentPresets = store.state.company.funTeamBiddingPresets || [];

        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            funTeamBiddingPresets: [
              ...currentPresets,
              { name: newPresetName.value, questions: selectedQuestions.value }
            ]
          });
          store.commit('addToCustomTeamBiddingPresets', {
            name: newPresetName.value,
            questions: selectedQuestions.value
          });
          successToast(t('funTeamBidding.customQuestions.addedToCustom'));
        } catch (e) {
          errorToast(e);
        } finally {
          showNewPresetModal.value = false;
          newPresetLoading.value = false;
          newPresetName.value = '';
        }
      };

      const viewPresets = () => {
        console.log(presets.value);
      };

      return {
        customQuestions,
        presets,
        questionEdited,
        typeOptions,
        selectedQuestions,
        showNewPresetModal,
        newPresetLoading,
        newPresetName,
        addNewPreset,
        editQuestion,
        editQuestionFromRow,
        handleSaveCustomQuestion,
        addNew,
        viewPresets,
        deleteCustomQuestion,
        handleSetOptionsForQuestion
      };
    }
  });
