
  import { computed, ref, Ref } from 'vue';
  import { defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import firebase from 'firebase';

  import BaseBox from '@/components/common/BaseBox.vue';
  import BaseBrandingItem from '@/components/common/BaseBrandingItem.vue';
  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import useToast from '@/composables/use-toast';

  import { avatarList, AvatarObject } from '@/constants/avatars';

  const storage = firebase.storage().ref();
  const db = firebase.firestore();

  export default defineComponent({
    name: 'AdminPanelBranding',
    components: {
      BaseBox,
      BaseBrandingItem,
      AdminPanelHeader
    },
    setup() {
      const store = useStore();
      const { successToast, errorToast } = useToast();
      const { t } = useI18n();

      const name = computed(() => store.state.company.name);
      const brandColor = computed(() => store.state.company.brandColor);
      const logo = computed(() => store.state.company.logo);
      const currentAvatarsPack = computed(() => store.state.company.avatars);
      const saveLoading: Ref<string> = ref('');
      const avatarsLoading: Ref<string> = ref('');
      const avatars: Ref = ref(avatarList);

      const changeValue = async (color: string) => {
        if (color.length === 7) {
          saveLoading.value = 'brandColor';

          try {
            await db.doc(`companies/${store.state.company.id}`).update({
              brandColor: color
            });

            store.commit('setBrandingColor', color);

            setTimeout(() => {
              saveLoading.value = '';
              successToast(t('adminPanel.branding.brandingUpdated'));
            }, 400);
          } catch (e) {
            saveLoading.value = '';
            errorToast(e);
          }
        }
      };

      const uploadLogo = (event: any) => {
        saveLoading.value = 'logo';

        const uploadTask = storage
          .child('logos/' + `${name.value}_${event.files[0].name}`)
          .put(event.files[0]);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, null, null, async () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(async downloadURL => {
              await db.collection('companies').doc(store.state.company.id).update({
                logo: downloadURL
              });

              successToast(t('adminPanel.branding.logoUpdated'));
              saveLoading.value = '';

              store.commit('updateLogo', downloadURL);
            })
            .catch(e => {
              saveLoading.value = '';

              errorToast(e);
            });
        });
      };

      const saveAvatarsPack = async (avatars: AvatarObject) => {
        if (avatars.name === currentAvatarsPack.value.name) {
          return;
        }

        avatarsLoading.value = avatars.name;

        try {
          await db.doc(`companies/${store.state.company.id}`).update({ avatars });

          store.commit('setAvatarsPack', avatars);

          setTimeout(() => {
            avatarsLoading.value = '';
            successToast(t('adminPanel.branding.avatarsUpdated'));
          }, 200);
        } catch (e) {
          errorToast(e);
        }
      };

      return {
        brandColor,
        logo,
        changeValue,
        uploadLogo,
        saveLoading,
        avatars,
        currentAvatarsPack,
        saveAvatarsPack,
        avatarsLoading
      };
    }
  });
