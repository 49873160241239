
  import { computed, ref, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BaseBox from '@/components/common/BaseBox.vue';
  import useToast from '@/composables/use-toast';
  const db = firebase.firestore();

  export default {
    name: 'AdminPanelIceBreakerSettings',
    components: {
      AdminPanelHeader,
      BaseBox
    },
    setup() {
      const store = useStore();
      const { successToast, errorToast } = useToast();
      const { t } = useI18n();

      const saveLoading: Ref<boolean> = ref(false);

      const funIceBreakerSettings: ComputedRef<any> = computed(
        () => store.state.company.funIceBreakerSettings
      );

      const saveIceBreakerConfigSettings = async () => {
        try {
          await db.doc(`companies/${store.state.company.id}`).update({
            funIceBreakerSettings: {
              defaultNoOfQuestions: Number(funIceBreakerSettings.value.defaultNoOfQuestions)
            }
          });

          store.commit('saveFunIceBreakerSettings', funIceBreakerSettings.value);
          successToast(t('success.saved'));
        } catch (e) {
          errorToast(e);
        }
      };

      return {
        saveIceBreakerConfigSettings,
        saveLoading,
        funIceBreakerSettings
      };
    }
  };
