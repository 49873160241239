
  import { computed, defineComponent, Ref, ref } from 'vue';
  import { format } from 'date-fns';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';

  import AdminPanelHeader from '@/components/admin-panel/AdminPanelHeader.vue';
  import BaseBox from '@/components/common/BaseBox.vue';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  import useToast from '@/composables/use-toast';
  import {
    MONEY_FOR_REFERRING_TO_OTHERS,
    MONEY_FOR_REGISTER_THROUGH_REFERRAL
  } from '@/constants/referrals';
  import { enGB, pl } from 'date-fns/locale';

  export default defineComponent({
    name: 'AdminPanelReferralProgram',
    components: {
      AdminPanelHeader,
      BaseBox,
      BaseButtonWithSpinner
    },
    setup() {
      const store = useStore();
      const { successToast, errorToast } = useToast();
      const { t, locale } = useI18n();

      const moneyForReferring = ref(MONEY_FOR_REFERRING_TO_OTHERS);
      const email: Ref<string> = ref('');
      const emailLoading: Ref<boolean> = ref(false);

      const referralCode = computed(() => store.state.company.referralCode);
      const referralData = computed(() => store.state.company.referralData);
      const currency = computed(() => store.state.company.currency);

      const sendInvite = async () => {
        emailLoading.value = true;

        try {
          await store.dispatch('sendInviteThroughReferral', {
            locale: locale.value,
            companyAdminEmail: store.state.user.email,
            companyName: store.state.company.name,
            registerLink: `${process.env.VUE_APP_BASE_URL}/register`,
            referralCode: referralCode.value,
            toEmail: email.value,
            referralDiscountPLN: MONEY_FOR_REGISTER_THROUGH_REFERRAL.PLN,
            referralDiscountEUR: MONEY_FOR_REGISTER_THROUGH_REFERRAL.EUR
          });
          successToast(t('adminPanel.referral.inviteEmailSent'));
        } catch {
          errorToast(t('adminPanel.referral.inviteEmailError'));
        } finally {
          emailLoading.value = false;
        }
      };

      const getDate = (date: number): string => {
        return format(date, 'dd MMM yyyy, HH:mm', {
          locale: locale.value === 'pl' ? pl : enGB
        });
      };

      const copy = (str: string): void => {
        navigator.clipboard.writeText(str);
        successToast(t('common.copiedToClipboard'));
      };

      return {
        copy,
        referralCode,
        sendInvite,
        moneyForReferring,
        referralData,
        getDate,
        email,
        currency,
        emailLoading
      };
    }
  });
