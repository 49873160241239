
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { format } from 'date-fns';
  import { pl, enGB } from 'date-fns/locale';
  import { useI18n } from 'vue-i18n';

  import BaseBox from '@/components/common/BaseBox.vue';
  import { GAME_NAMES_HUMANIZED, GAME_NAMES } from '@/helpers';

  export default defineComponent({
    name: 'AdminPanelDashboard',
    components: { BaseBox },
    setup() {
      const store = useStore();
      const { locale } = useI18n();

      const userCount = computed(() => store.state.users?.length);
      const gamesCount = computed(
        () =>
          store.state.games?.length +
          store.state.funIceBreakerGames?.length +
          store.state.funTeamBiddingGames?.length
      );
      const company = computed(() => {
        return store.state.company;
      });

      const gamesSorted = computed(() => {
        const games = store.state.company.package?.gamesLeft;

        return games ? Object.entries(games).sort((a: any, b: any) => b[1] - a[1]) : [];
      });

      const formatDate = (date: any) => {
        return format(date.seconds ? date.seconds : new Date(date), 'dd MMM yyyy', {
          locale: locale.value === 'pl' ? pl : enGB
        });
      };

      const imageConfig = {
        [GAME_NAMES.FUN_BUSINESS_GAME]: require('@/assets/logos/business-game/logo-accent-tile.svg'),
        [GAME_NAMES.FUN_TEAM_BIDDING]: require('@/assets/logos/logo-funteambidding.svg'),
        [GAME_NAMES.FUN_TEAM_CLUES]: require('@/assets/logos/logo-funteamclues.svg'),
        [GAME_NAMES.FUN_ICE_BREAKER]: require('@/assets/logos/logo-funicebreaker.svg')
      };

      const createNewGameConfig = {
        [GAME_NAMES.FUN_BUSINESS_GAME]: '/funbusinessgame/new',
        [GAME_NAMES.FUN_TEAM_BIDDING]: '/funteambidding/new',
        [GAME_NAMES.FUN_TEAM_CLUES]: '/funteamclues/new',
        [GAME_NAMES.FUN_ICE_BREAKER]: '/funicebreaker/new'
      };

      const adjustGameConfig = {
        [GAME_NAMES.FUN_BUSINESS_GAME]: '/dashboard/funbusinessgame/board-config',
        [GAME_NAMES.FUN_TEAM_BIDDING]: '/dashboard/funteambidding/custom-questions',
        [GAME_NAMES.FUN_TEAM_CLUES]: '/dashboard/funteamclues/custom-questions',
        [GAME_NAMES.FUN_ICE_BREAKER]: '/dashboard/funicebreaker/custom-questions'
      };

      return {
        userCount,
        gamesCount,
        company,
        formatDate,
        imageConfig,
        GAME_NAMES_HUMANIZED,
        createNewGameConfig,
        adjustGameConfig,
        gamesSorted
      };
    }
  });
