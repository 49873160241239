import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_2 = { class: "mr2" }
const _hoisted_3 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_4 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_5 = { class: "mr2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { key: 4 }
const _hoisted_11 = {
  key: 0,
  class: "flex"
}
const _hoisted_12 = { class: "flex flex-column items-center justify-center my3" }
const _hoisted_13 = { class: "mb3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_AdminPanelHeader = _resolveComponent("AdminPanelHeader")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_AdminPanelHeader, {
      title: _ctx.$t('adminPanel.activeGames.title')
    }, {
      default: _withCtx(() => [
        (!_ctx.packageOfGames)
          ? (_openBlock(), _createBlock("div", _hoisted_1, [
              _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('adminPanel.activeGames.noActivePackage')), 1),
              _createVNode(_component_Button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/dashboard')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.activeGames.buyGames')), 1)
                ]),
                _: 1
              })
            ]))
          : (_ctx.gamesLeft > 0)
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                (_ctx.games.length)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push(`/${_ctx.gameNameForLinks}/new`)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('newGame.startNewGame')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('adminPanel.activeGames.playedAllGames')), 1),
                _createVNode(_component_Button, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/dashboard')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.activeGames.buyMoreGames')), 1)
                  ]),
                  _: 1
                })
              ]))
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_DataTable, {
      value: _ctx.games,
      responsiveLayout: "scroll"
    }, _createSlots({
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "players",
          header: _ctx.$t('adminPanel.activeGames.headers.players')
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.players.length), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "players",
          header: _ctx.$t('adminPanel.activeGames.headers.createdBy')
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.players.find(p => p.admin)?.email || slotProps.data.gameCreator?.email), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "status",
          header: _ctx.$t('adminPanel.activeGames.headers.status')
        }, {
          body: _withCtx((slotProps) => [
            (
              slotProps.data.settings.gameFinishTime &&
              _ctx.getTimeLeft(slotProps.data.settings.gameFinishTime) === '--'
            )
              ? (_openBlock(), _createBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('adminPanel.activeGames.timeEnded')), 1))
              : (slotProps.data.gameEnded)
                ? (_openBlock(), _createBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('adminPanel.activeGames.ended')), 1))
                : (slotProps.data.gameCancelled)
                  ? (_openBlock(), _createBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('adminPanel.activeGames.cancelled')), 1))
                  : (!slotProps.data.gameStarted)
                    ? (_openBlock(), _createBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('adminPanel.activeGames.notYetStarted')), 1))
                    : (slotProps.data.gameStarted && !slotProps.data.gameEnded)
                      ? (_openBlock(), _createBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('adminPanel.activeGames.inProgress')), 1))
                      : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "createdOn",
          header: _ctx.$t('adminPanel.activeGames.headers.createdOn')
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.createdOn)), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "timeLeft",
          header: _ctx.$t('adminPanel.activeGames.headers.timeLeft')
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.settings.gameFinishTime
              ? _ctx.getTimeLeft(slotProps.data.settings.gameFinishTime)
              : '--'), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "id",
          header: _ctx.$t('adminPanel.activeGames.headers.link')
        }, {
          body: _withCtx((slotProps) => [
            (!slotProps.data.gameCancelled)
              ? (_openBlock(), _createBlock("div", _hoisted_11, [
                  _createVNode("a", {
                    href: _ctx.getLink(slotProps.data.id),
                    target: "_blank",
                    class: "mr1"
                  }, [
                    _createVNode(_component_Button, { class: "p-button-sm" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.activeGames.goToGame')), 1)
                      ]),
                      _: 1
                    })
                  ], 8, ["href"]),
                  _createVNode(_component_Button, {
                    class: "p-button-outlined p-button-sm",
                    onClick: ($event: any) => (_ctx.copy(slotProps.data.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.activeGames.copyLink')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 2
    }, [
      (_ctx.gamesLeft > 0)
        ? {
            name: "empty",
            fn: _withCtx(() => [
              _createVNode("div", _hoisted_12, [
                _createVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('adminPanel.activeGames.noGamesAddedYet')), 1),
                _createVNode(_component_Button, {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push(`/${_ctx.gameNameForLinks}/new`)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.activeGames.startFirstGame')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          }
        : undefined
    ]), 1032, ["value"])
  ]))
}