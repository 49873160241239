import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default function () {
  const store = useStore();
  const { locale } = useI18n();

  const sendInviteEmail = async (toEmail: string, query: string) => {
    if (!toEmail) {
      return;
    }

    await store.dispatch('sendInviteEmail', {
      locale: locale.value,
      companyAdmin: store.state.user.email,
      companyName: store.state.company.name,
      registerLink: `${process.env.VUE_APP_BASE_URL}/register${query}`,
      toEmail
    });
  };

  return { sendInviteEmail };
}
