<template>
  <div class="feedback">
    <div class="feedback__open flex flex-column">
      <div v-if="feedbackSent" class="center">
        {{ $t('endResultsThanksForFeedback') }}
      </div>
      <template v-else>
        <div class="mb3 feedback__title">{{ $t('endResultsFeedbackHeading') }}</div>
        <ul class="feedback__rating flex">
          <li
            class="feedback__rating-item flex items-center justify-center"
            v-for="i in 10"
            :key="i"
            @click="feedbackRating = i"
            :class="{ 'feedback__rating-item--active': feedbackRating === i }"
          >
            {{ i }}
          </li>
        </ul>
        <Textarea :placeholder="$t('endResultsFeedbackPlaceholder')" v-model="feedback" rows="5" />
        <BaseButtonWithSpinner :loading="feedbackLoading" @click="handleSendFeedback">
          {{ $t('endResultsSendFeedback') }}
        </BaseButtonWithSpinner>
      </template>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import useFirebase from '@/composables/use-firebase';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import useToast from '@/composables/use-toast';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'Feedback',
    components: { BaseButtonWithSpinner },
    setup() {
      const { sendFeedback, getCurrentUser } = useFirebase();
      let { currentRoute } = useRouter();
      const store = useStore();
      const user = getCurrentUser();
      const { warningToast } = useToast();
      const { t } = useI18n();

      const feedbackRating = ref(null);
      const feedback = ref('');
      const feedbackSent = ref(false);
      const feedbackLoading = ref(false);

      const handleSendFeedback = async () => {
        if (!feedbackRating.value && !feedback.value) {
          //show alert that it's empty
          warningToast(t('endResultsSendFeedbackWarning'));
          return;
        }

        feedbackLoading.value = true;
        try {
          await sendFeedback(
            Number(feedbackRating.value),
            feedback.value,
            null,
            store.state.company.id,
            store.state.company.name,
            user.email,
            true
          );

          feedbackSent.value = true;
        } catch (e) {
          console.log(e);
        } finally {
          feedbackLoading.value = false;
        }
      };
      return {
        feedback,
        feedbackRating,
        feedbackSent,
        feedbackLoading,
        handleSendFeedback
      };
    }
  };
</script>

<style scoped lang="scss">
  .feedback {
    padding: global-vars(ui-default-measure);
    border-radius: global-vars(ui-default-measure);
    background: white;

    i {
      position: absolute;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: -8px;
      right: -24px;
      cursor: pointer;
    }

    &__open {
      position: relative;
      padding: 6px;
      width: 300px;
      max-width: 100%;
    }

    &__hidden {
      padding: global-vars(ui-default-measure);
      cursor: pointer;
      @include font(14px, 600);
    }

    textarea {
      margin-bottom: global-vars(ui-default-measure2x);
    }

    &__title {
      @include font(16px, 600);
    }

    &__rating {
      margin-bottom: global-vars(ui-default-measure2x);

      &-item {
        border: 1px solid color(gray);
        width: global-vars(ui-default-measure3x);
        height: global-vars(ui-default-measure3x);
        border-radius: 4px;
        cursor: pointer;
        transition: 0.2s ease;

        &:not(:last-of-type) {
          margin-right: 4px;
        }

        &:hover {
          border: 1px solid color(accent);
          background: color(accent);
          color: white;
        }

        &--active {
          @include font(16px, 600);
          border: 1px solid color(accent);
          background: color(accent);
          color: white;
        }
      }
    }
  }
</style>
