import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = { class: "input-wrapper" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "ml2" }
const _hoisted_5 = { class: "ml2" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "ml1" }
const _hoisted_9 = { class: "admin-panel-board-config__edit ml2" }
const _hoisted_10 = { class: "edit__header flex justify-between items-center mb2" }
const _hoisted_11 = { class: "edit__header-title" }
const _hoisted_12 = {
  key: 0,
  class: "flex"
}
const _hoisted_13 = { class: "ml1" }
const _hoisted_14 = {
  key: 0,
  class: "edit__wrapper p2"
}
const _hoisted_15 = {
  key: 0,
  class: "edit__item py1 flex items-center"
}
const _hoisted_16 = { class: "label mr3" }
const _hoisted_17 = { class: "value" }
const _hoisted_18 = {
  key: 1,
  class: "edit__item py1 flex items-center"
}
const _hoisted_19 = { class: "label mr3" }
const _hoisted_20 = { class: "value" }
const _hoisted_21 = {
  key: 2,
  class: "edit__item py1 flex items-center"
}
const _hoisted_22 = { class: "label mr3" }
const _hoisted_23 = { class: "value" }
const _hoisted_24 = {
  key: 3,
  class: "edit__item py1 flex items-center"
}
const _hoisted_25 = { class: "label mr3" }
const _hoisted_26 = { class: "value" }
const _hoisted_27 = {
  key: 4,
  class: "edit__item py1 flex items-center"
}
const _hoisted_28 = { class: "label mr3" }
const _hoisted_29 = { class: "value" }
const _hoisted_30 = {
  key: 5,
  class: "edit__item py1 flex items-center"
}
const _hoisted_31 = { class: "label mr3" }
const _hoisted_32 = { class: "value" }
const _hoisted_33 = { class: "edit__item py1 flex items-center" }
const _hoisted_34 = { class: "label mr3" }
const _hoisted_35 = { class: "value" }
const _hoisted_36 = {
  key: 6,
  class: "edit__item py1 flex items-center"
}
const _hoisted_37 = { class: "label mr3" }
const _hoisted_38 = { class: "flex flex-column" }
const _hoisted_39 = { class: "subvalue__section" }
const _hoisted_40 = { class: "value flex items-center subvalue" }
const _hoisted_41 = { class: "value--secondary mr1" }
const _hoisted_42 = { class: "value flex items-center subvalue" }
const _hoisted_43 = { class: "value--secondary mr1" }
const _hoisted_44 = { class: "value flex items-center subvalue" }
const _hoisted_45 = { class: "value--secondary mr1" }
const _hoisted_46 = { class: "value flex items-center subvalue" }
const _hoisted_47 = { class: "value--secondary mr1" }
const _hoisted_48 = { class: "subvalue__section" }
const _hoisted_49 = { class: "value flex items-center subvalue" }
const _hoisted_50 = { class: "value--secondary mr1" }
const _hoisted_51 = { class: "value flex items-center subvalue" }
const _hoisted_52 = { class: "value--secondary mr1" }
const _hoisted_53 = { class: "value flex items-center subvalue" }
const _hoisted_54 = { class: "value--secondary mr1" }
const _hoisted_55 = { class: "value flex items-center subvalue" }
const _hoisted_56 = { class: "value--secondary mr1" }
const _hoisted_57 = { class: "subvalue__section" }
const _hoisted_58 = { class: "value flex items-center subvalue" }
const _hoisted_59 = { class: "value--secondary mr1" }
const _hoisted_60 = { class: "value flex items-center subvalue" }
const _hoisted_61 = { class: "value--secondary mr1" }
const _hoisted_62 = { class: "value flex items-center subvalue" }
const _hoisted_63 = { class: "value--secondary mr1" }
const _hoisted_64 = { class: "value flex items-center subvalue" }
const _hoisted_65 = { class: "value--secondary mr1" }
const _hoisted_66 = { class: "subvalue__section" }
const _hoisted_67 = { class: "value flex items-center subvalue" }
const _hoisted_68 = { class: "value--secondary mr1" }
const _hoisted_69 = { class: "value flex items-center subvalue" }
const _hoisted_70 = { class: "value--secondary mr1" }
const _hoisted_71 = { class: "value flex items-center subvalue" }
const _hoisted_72 = { class: "value--secondary mr1" }
const _hoisted_73 = { class: "board-card board-card--big" }
const _hoisted_74 = {
  key: 0,
  class: "board-card board-card--big"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownSelect = _resolveComponent("DropdownSelect")!
  const _component_BaseButtonWithSpinner = _resolveComponent("BaseButtonWithSpinner")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AdminPanelHeader = _resolveComponent("AdminPanelHeader")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BaseBrandingItem = _resolveComponent("BaseBrandingItem")!
  const _component_BoardOutside = _resolveComponent("BoardOutside")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_BoardCardDescription = _resolveComponent("BoardCardDescription")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      class: "admin-panel-board-config__modal",
      visible: _ctx.showNewTemplateModal,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showNewTemplateModal = $event))
    }, {
      header: _withCtx(() => [
        _createVNode("h3", null, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.selectNewTemplate')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_BaseButtonWithSpinner, {
          onClick: _ctx.handleSelectNewTemplate,
          loading: _ctx.newTemplateLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.apply')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ]),
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("label", null, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.boardTemplate')), 1),
          _createVNode(_component_DropdownSelect, {
            modelValue: _ctx.boardTemplate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.boardTemplate = $event)),
            options: _ctx.boardTemplatesWithCustom,
            "option-label": "label",
            "option-value": "value"
          }, null, 8, ["modelValue", "options"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      class: "admin-panel-board-config__add-template-modal",
      visible: _ctx.showSaveAsNewTemplateModal,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSaveAsNewTemplateModal = $event))
    }, {
      header: _withCtx(() => [
        _createVNode("h3", null, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.saveAsNew')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_BaseButtonWithSpinner, {
          onClick: _ctx.handleSaveAsNewTemplate,
          loading: _ctx.saveAsNewTemplateLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.save')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ]),
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("label", null, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.templateName')), 1),
          _createVNode(_component_Input, {
            modelValue: _ctx.saveAsNewTemplateName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveAsNewTemplateName = $event)),
            class: "p-inputtext-sm",
            onKeyup: _withKeys(_ctx.handleSaveAsNewTemplate, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_AdminPanelHeader, {
      title: _ctx.$t('adminPanel.businessGame.boardConfig.headerTitle')
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            class: "p-button-outlined",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showNewTemplateModal = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.selectOtherTemplate')), 1)
            ]),
            _: 1
          }),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              class: "p-button-outlined",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showSaveAsNewTemplateModal = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.saveCurrentConfig')), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              class: "ml3",
              onClick: _ctx.previewBoardConfig
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.previewBoard')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode("div", _hoisted_6, [
      _createVNode(_component_DataTable, {
        class: "admin-panel-board-config__all p-datatable-sm",
        value: _ctx.boardConfig,
        responsiveLayout: "scroll",
        onRowClick: _ctx.editCardFromRow
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "id",
            header: _ctx.$t('adminPanel.businessGame.boardConfig.position')
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "type",
            header: _ctx.$t('adminPanel.businessGame.boardConfig.type')
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "title",
            header: _ctx.$t('adminPanel.businessGame.boardConfig.title')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode("b", null, _toDisplayString(slotProps.data.title), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "price",
            header: _ctx.$t('adminPanel.businessGame.boardConfig.price')
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "color",
            header: _ctx.$t('adminPanel.businessGame.boardConfig.sectionColor')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode("div", {
                class: "admin-panel-board-config__color",
                style: { background: slotProps.data.color }
              }, null, 4)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "cannotBeOwned",
            header: _ctx.$t('adminPanel.businessGame.boardConfig.canBeBought')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.cannotBeOwned ? _ctx.$t('common.no') : _ctx.$t('common.yes')), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "actions",
            header: _ctx.$t('gamesCommon.actions')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode("div", _hoisted_7, [
                _createVNode(_component_Button, {
                  class: "p-button-outlined p-button-sm",
                  onClick: ($event: any) => (_ctx.editCard(slotProps.data.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.edit')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode("div", _hoisted_8, [
                  _createVNode(_component_Button, {
                    class: "p-button-outlined p-button-sm",
                    onClick: ($event: any) => (_ctx.previewCard(slotProps.data.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.preview')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["value", "onRowClick"]),
      _createVNode("div", _hoisted_9, [
        _createVNode("div", _hoisted_10, [
          _createVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.editor')), 1),
          (_ctx.cardEdited)
            ? (_openBlock(), _createBlock("div", _hoisted_12, [
                _createVNode(_component_Button, {
                  class: "ml1",
                  onClick: _ctx.handleSaveBoard
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.saveCard')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode("div", _hoisted_13, [
                  _createVNode(_component_Button, {
                    class: "p-button-outlined",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.previewCard(_ctx.cardEdited.id)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.preview')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.cardEdited)
          ? (_openBlock(), _createBlock("div", _hoisted_14, [
              (_ctx.cardEdited.id)
                ? (_openBlock(), _createBlock("div", _hoisted_15, [
                    _createVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.position')), 1),
                    _createVNode("div", _hoisted_17, _toDisplayString(_ctx.cardEdited.id), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.cardEdited.type)
                ? (_openBlock(), _createBlock("div", _hoisted_18, [
                    _createVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.type')), 1),
                    _createVNode("div", _hoisted_20, _toDisplayString(_ctx.cardEdited.type), 1)
                  ]))
                : _createCommentVNode("", true),
              ('title' in _ctx.cardEdited)
                ? (_openBlock(), _createBlock("div", _hoisted_21, [
                    _createVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.title')), 1),
                    _createVNode("div", _hoisted_23, [
                      _createVNode(_component_Input, {
                        modelValue: _ctx.cardEdited.title,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.cardEdited.title = $event)),
                        class: "p-inputtext-sm"
                      }, null, 8, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              ('price' in _ctx.cardEdited)
                ? (_openBlock(), _createBlock("div", _hoisted_24, [
                    _createVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.price')), 1),
                    _createVNode("div", _hoisted_26, [
                      _createVNode(_component_Input, {
                        modelValue: _ctx.cardEdited.price,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.cardEdited.price = $event)),
                        class: "p-inputtext-sm"
                      }, null, 8, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              ('baseCharge' in _ctx.cardEdited)
                ? (_openBlock(), _createBlock("div", _hoisted_27, [
                    _createVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.baseCharge')), 1),
                    _createVNode("div", _hoisted_29, [
                      _createVNode(_component_Input, {
                        modelValue: _ctx.cardEdited.baseCharge,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.cardEdited.baseCharge = $event)),
                        class: "p-inputtext-sm"
                      }, null, 8, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              ('color' in _ctx.cardEdited)
                ? (_openBlock(), _createBlock("div", _hoisted_30, [
                    _createVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.color')), 1),
                    _createVNode("div", _hoisted_32, [
                      _createVNode(_component_BaseBrandingItem, {
                        value: _ctx.cardEdited.color,
                        name: "Color",
                        onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateColorInEditedCard($event)))
                      }, null, 8, ["value"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createVNode("div", _hoisted_33, [
                _createVNode("div", _hoisted_34, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.canBeBought')), 1),
                _createVNode("div", _hoisted_35, _toDisplayString(_ctx.cardEdited.cannotBeOwned ? _ctx.$t('common.no') : _ctx.$t('common.yes')), 1)
              ]),
              (_ctx.cardEdited.prices)
                ? (_openBlock(), _createBlock("div", _hoisted_36, [
                    _createVNode("div", _hoisted_37, _toDisplayString(_ctx.$t('adminPanel.businessGame.boardConfig.prices')), 1),
                    _createVNode("div", _hoisted_38, [
                      _createVNode("div", _hoisted_39, [
                        _createVNode("div", _hoisted_40, [
                          _createVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('boardCard.toEmploy')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryOne[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryOne.buy,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.cardEdited.prices.categoryOne.buy = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_42, [
                          _createVNode("div", _hoisted_43, _toDisplayString(_ctx.$t('boardCard.letGo')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryOne[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryOne.sell,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.cardEdited.prices.categoryOne.sell = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_44, [
                          _createVNode("div", _hoisted_45, _toDisplayString(_ctx.$t('boardCard.toPromote')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryOne[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryOne.promote,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.cardEdited.prices.categoryOne.promote = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_46, [
                          _createVNode("div", _hoisted_47, _toDisplayString(_ctx.$t('boardCard.costForOtherPlayers')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryOne[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryOne.charge,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.cardEdited.prices.categoryOne.charge = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createVNode("div", _hoisted_48, [
                        _createVNode("div", _hoisted_49, [
                          _createVNode("div", _hoisted_50, _toDisplayString(_ctx.$t('boardCard.toEmploy')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryTwo[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryTwo.buy,
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.cardEdited.prices.categoryTwo.buy = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_51, [
                          _createVNode("div", _hoisted_52, _toDisplayString(_ctx.$t('boardCard.letGo')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryTwo[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryTwo.sell,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.cardEdited.prices.categoryTwo.sell = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_53, [
                          _createVNode("div", _hoisted_54, _toDisplayString(_ctx.$t('boardCard.toPromote')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryTwo[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryTwo.promote,
                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.cardEdited.prices.categoryTwo.promote = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_55, [
                          _createVNode("div", _hoisted_56, _toDisplayString(_ctx.$t('boardCard.costForOtherPlayers')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryTwo[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryTwo.charge,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.cardEdited.prices.categoryTwo.charge = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createVNode("div", _hoisted_57, [
                        _createVNode("div", _hoisted_58, [
                          _createVNode("div", _hoisted_59, _toDisplayString(_ctx.$t('boardCard.toEmploy')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryThree[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryThree.buy,
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (_ctx.cardEdited.prices.categoryThree.buy = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_60, [
                          _createVNode("div", _hoisted_61, _toDisplayString(_ctx.$t('boardCard.letGo')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryThree[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryThree.sell,
                            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_ctx.cardEdited.prices.categoryThree.sell = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_62, [
                          _createVNode("div", _hoisted_63, _toDisplayString(_ctx.$t('boardCard.toPromote')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryThree[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryThree.promote,
                            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (_ctx.cardEdited.prices.categoryThree.promote = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_64, [
                          _createVNode("div", _hoisted_65, _toDisplayString(_ctx.$t('boardCard.costForOtherPlayers')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryThree[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryThree.charge,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (_ctx.cardEdited.prices.categoryThree.charge = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createVNode("div", _hoisted_66, [
                        _createVNode("div", _hoisted_67, [
                          _createVNode("div", _hoisted_68, _toDisplayString(_ctx.$t('boardCard.toEmploy')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryFour[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryFour.buy,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (_ctx.cardEdited.prices.categoryFour.buy = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_69, [
                          _createVNode("div", _hoisted_70, _toDisplayString(_ctx.$t('boardCard.letGo')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryFour[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryFour.sell,
                            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (_ctx.cardEdited.prices.categoryFour.sell = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode("div", _hoisted_71, [
                          _createVNode("div", _hoisted_72, _toDisplayString(_ctx.$t('boardCard.costForOtherPlayers')) + " " + _toDisplayString(_ctx.upgradeConfig.categoryFour[_ctx.$i18n.locale]), 1),
                          _createVNode(_component_Input, {
                            modelValue: _ctx.cardEdited.prices.categoryFour.charge,
                            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (_ctx.cardEdited.prices.categoryFour.charge = $event)),
                            class: "p-inputtext-sm"
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_Sidebar, {
      class: "admin-panel-board-config__board-sidebar",
      visible: _ctx.boardPreviewVisible,
      "onUpdate:visible": _cache[27] || (_cache[27] = ($event: any) => (_ctx.boardPreviewVisible = $event)),
      position: "right"
    }, {
      default: _withCtx(() => [
        (_ctx.boardConfig && _ctx.boardConfig.length)
          ? (_openBlock(), _createBlock(_component_BoardOutside, {
              key: 0,
              class: "admin-panel-board-config",
              "board-config": _ctx.boardConfig,
              "your-player": {},
              players: [],
              "bonus-money": 0
            }, null, 8, ["board-config"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Sidebar, {
      class: "admin-panel-board-config__card-sidebar",
      visible: _ctx.cardPreviewVisible,
      "onUpdate:visible": _cache[28] || (_cache[28] = ($event: any) => (_ctx.cardPreviewVisible = $event)),
      position: "right"
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_73, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.boardCardComponent), _mergeProps(_ctx.cardPreviewed, {
            big: true,
            "upgrade-config": _ctx.upgradeConfig
          }), null, 16, ["upgrade-config"]))
        ]),
        (_ctx.BUYABLE.includes(_ctx.cardPreviewed.type) && _ctx.cardPreviewed.prices)
          ? (_openBlock(), _createBlock("div", _hoisted_74, [
              _createVNode(_component_BoardCardDescription, _mergeProps(_ctx.cardPreviewed, {
                big: true,
                "upgrade-config": _ctx.upgradeConfig
              }), null, 16, ["upgrade-config"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}