<template>
  <div class="flex mb3">
    <div class="h2 title mr3">
      {{ $attrs.title }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'AdminPanelHeader'
  };
</script>

<style scoped lang="scss">
  .title {
    font-weight: 600;
  }
</style>
